export default [
  {
    path: '/dashboard/produk-kategori',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'product-category-index',
        component: () => import('@/views/dashboard/product-category/page-list.vue'),
      },
    ],
  },
]