export default [
  {
    path: '/dashboard/notifikasi',
    component: () => import(/* layout untuk halaman dashboard */ '@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'notifikasi-index',
        component: () => import(/* views: product index */ '@/views/dashboard/notification/page-list.vue'),
      },
    ],
  },
]