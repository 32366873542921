<script setup lang="ts">
  import { computed } from 'vue'
  import { SelectHTMLAttributes } from 'vue'

  const props = defineProps<{
    modelValue: SelectHTMLAttributes['value'],
    icon?: string,
    disabled?: boolean,
    label?: string,
    required?: boolean,
    error?: string[],
    placeholder?: string,
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: typeof props.modelValue): void,
    (e: 'change', value: typeof props.modelValue): void,
  }>()

  const computedValue = computed({
    set: value => {
      emit('update:modelValue', value)
      emit('change', value)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <div>
    <label v-if="label">
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative flex items-center">
      <select
        v-model="computedValue"
        :required="required"
        class="form-select form-input pr-8 appearance-none cursor-pointer"
        :class="{
          'indent-6': icon,
        }"
        :disabled="disabled">
        <option v-if="placeholder" :value="null" hidden>
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>

      <div v-if="icon" class="absolute left-3 text-gray-500 z-10 pointer-events-none">
        <fa-icon :icon="icon" />
      </div>
      <div class="absolute right-2 text-xs z-10 pointer-events-none">
        <fa-icon icon="chevron-down" />
      </div>
    </div>
    <g-form-error :value="error" />
  </div>
</template>

<style lang="postcss" scoped>
</style>