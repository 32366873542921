import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router'
// auth
import auth from './auth'
// dashboard
import invoice from './dashboard/invoice'
import notification from './dashboard/notification'
import order from './dashboard/order'
import paymentMethod from './dashboard/payment-method'
import product from './dashboard/product'
import productCategory from './dashboard/product-category'
import user from './dashboard/user'
// shop
import homeShop from './shop'

declare module 'vue-router' {
  interface RouteMeta {
    title: string,
  }
}

const routes: RouteRecordRaw[] = [
  // auth
  ...auth,

  // dashboard
  {
    path: '/dashboard',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/dashboard/home/page-index.vue'),
      },
    ],
  },
  {
    path: '/dashboard/pengaturan-sistem',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'system setting',
        component: () => import('@/views/dashboard/system-setting/page-list.vue'),
      },
    ],
  },
  ...product,
  ...productCategory,
  ...order,
  ...user,
  ...paymentMethod,
  ...notification,
  ...invoice,

  // shop
  ...homeShop,
]


const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
