export const stringTitle = (str: string) => {
  return String(str).split(' ').map(stringUpperFirst).join(' ')
}
export const stringUpperFirst = (str: string) => {
  return String(str).charAt(0).toUpperCase() + str.substring(1)
}

export default {
  stringTitle,
  stringUpperFirst,
}