export default [
  {
    path: '/dashboard/metode-pembayaran',
    component: () => import(/* layout untuk halaman dashboard */ '@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'payment-method-index',
        component: () => import(/* views: product index */ '@/views/dashboard/payment-method/page-list.vue'),
      },
    ],
  },
]