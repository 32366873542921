import { App } from 'vue'
import GAvatar from './app-avatar.vue'
import GButton from './app-button.vue'
import GCheckbox from './app-checkbox.vue'
import GDatePicker from './app-datepicker.vue'
import GDropdown from './app-dropdown.vue'
import GEmpty from './app-empty.vue'
import FormError from './app-form-error.vue'
import Image from './app-image.vue'
import GInfoSection from './app-info-section.vue'
import GInfo from './app-info.vue'
import GInput from './app-input.vue'
import GModal from './app-modal.vue'
import GNumeric from './app-numeric.vue'
import GPagination from './app-pagination.vue'
import GSearch from './app-search.vue'
import GAppSelect from './app-select.vue'
import GTable from './app-table.vue'
import GToast from './app-toast.vue'
import FontAwesome from './font-awesome'
import GLoading from './shop-loading.vue'

/**
 * Here you can register components
 * that will be available to all
 * components in the project.
 */
export default {
  install(app: App) {
    app.use(FontAwesome)
    app.component('GAvatar', GAvatar)
    app.component('GButton', GButton)
    app.component('GCheckbox', GCheckbox)
    app.component('GDatePicker', GDatePicker)
    app.component('GDropdown', GDropdown)
    app.component('GEmpty', GEmpty)
    app.component('GFormError', FormError)
    app.component('GImg', Image)
    app.component('GInfo', GInfo)
    app.component('GInfoSection', GInfoSection)
    app.component('GInput', GInput)
    app.component('GLoading', GLoading)
    app.component('GModal', GModal)
    app.component('GNumeric', GNumeric)
    app.component('GPagination', GPagination)
    app.component('GSearch', GSearch)
    app.component('GSelect', GAppSelect)
    app.component('GTable', GTable)
    app.component('GToast', GToast)
  },
}

declare module 'vue' {
  interface GlobalComponents {
    GAvatar: typeof GAvatar
    GButton: typeof GButton
    GCheckbox: typeof GCheckbox
    GDatePicker: typeof GDatePicker
    GDropdown: typeof GDropdown
    GEmpty: typeof GEmpty
    GFormError: typeof FormError
    GImg: typeof Image
    GInfo: typeof GInfo
    GInfoSection: typeof GInfoSection
    GInput: typeof GInput
    GLoading: typeof GLoading
    GModal: typeof GModal
    GNumeric: typeof GNumeric
    GPagination: typeof GPagination
    GSearch: typeof GSearch
    GSelect: typeof GAppSelect
    GTable: typeof GTable
    GToast: typeof GToast
  }
}

declare global {
  type GModal = InstanceType<typeof GModal>
}
