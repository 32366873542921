export default [
  {
    path: '/dashboard/faktur',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'invoice-index',
        component: () => import('@/views/dashboard/invoice/page-list.vue'),
      },
      {
        path: 'invoice',
        name: 'invoice-form',
        component: () => import('@/views/dashboard/invoice/import-form.vue'),
      },
      {
        path: ':id',
        name: 'invoice-detail',
        props: true,
        component: () => import('@/views/dashboard/invoice/show/page-show.vue'),
      },
    ],
  },
]