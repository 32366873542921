import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBan,
  faBars,
  faBell,
  faBox,
  faCartShopping,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faCoins,
  faComment,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faFileInvoiceDollar,
  faGears,
  faHouse,
  faImage,
  faLayerGroup,
  faMobile,
  faMoneyBillWaveAlt,
  faMoneyCheck,
  faPencil,
  faPlus,
  faReceipt,
  faRightFromBracket,
  faSearch,
  faSort,
  faSpinner,
  faSquarePen,
  faStore,
  faTrash,
  faUpload,
  faUser,
  faUserNinja,
  faUserSecret,
  faUserTie,
  faUsers,
  faWallet,
  faWineGlassEmpty,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { App } from 'vue'

library.add(
  faBan,
  faBars,
  faBell,
  faBox,
  faCartShopping,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faCoins,
  faComment,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeOpen,
  faEye,
  faFileInvoiceDollar,
  faGears,
  faHouse,
  faImage,
  faLayerGroup,
  faMobile,
  faMoneyBillWaveAlt,
  faMoneyCheck,
  faPencil,
  faPlus,
  faReceipt,
  faRightFromBracket,
  faSearch,
  faSort,
  faSpinner,
  faSquarePen,
  faStore,
  faTrash,
  faUpload,
  faUser,
  faUserNinja,
  faUserSecret,
  faUserTie,
  faUsers,
  faWallet,
  faWineGlassEmpty,
  faXmark,
)

export default {
  install(app: App) {
    app.component('FaIcon', FontAwesomeIcon)
  },
}

declare module 'vue' {
  interface GlobalComponents {
    FaIcon: typeof FontAwesomeIcon
  }
}
