import { abbreviateNumber } from 'js-abbreviation-number'

export default {
  number: (num: number) => {
    if (num && !isNaN(num)) {
      return new Intl.NumberFormat('id-ID').format(Number(num))
    }
    return '0'
  },

  numberAbbreviate: (num: string|number) => {
    if (!!Number(num)) {
      const symbols = ['', 'rb', 'jt', 'm', 'tr']
      return abbreviateNumber(Number(num), 0, { symbols })
    }
    return num
  },
}