import { defineStore } from 'pinia'
import { useApiStore } from '@/store/api'
import { ProductPriceSpecial } from '@/types/product'
import { Discount, OfficialStoreType, SalesPeople, User, UserOfficialStore } from '@/types/user'

interface State {
  user: User|SalesPeople|UserOfficialStore|null,
  total_debt: number,
  total_1_month: number,
  total_2_month: number,
  total_3_month: number,
  total_debt_bigger_than_3_months: number,
  total_debt_invoice: number,
  limit_debt: number,
  notification: {
    count: number,
    data: string[],
  },
  deviceKey: string,
  loggingOut: boolean,
  loading: boolean,
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    user: null,
    total_debt: 0,
    total_1_month: 0,
    total_2_month: 0,
    total_3_month: 0,
    total_debt_bigger_than_3_months: 0,
    total_debt_invoice: 0,
    limit_debt: 0,
    notification: {
      count: 0,
      data: [],
    },
    deviceKey: '',
    loggingOut: false,
    loading: false,
  }),
  getters: {
    isAdmin (state) {
      if (state.user) {
        return state.user.role && state.user.role !== 'sales person'
      }

      return false
    },
    isSalesPerson (state) {
      if (state.user) {
        return state.user.role && state.user.role === 'sales person'
      }

      return false
    },
    isOfficialStore (state) {
      if (state.user) {
        return !state.user.role
      }
      return false
    },
    discounts (state): Discount[] {
      if (state.user && state.user.role === null) {
        const user = state.user as UserOfficialStore

        return user.official_store.discounts?.sort((a, b)=> a.min - b.min) ?? []
      }

      return []
    },
    priceSpecials (state): ProductPriceSpecial[] {
      if (state.user && state.user.role === null) {
        const user = state.user as UserOfficialStore

        return user.official_store.price_specials
      }

      return []
    },
    typeStore (state): OfficialStoreType {
      if (state.user && state.user.role === null) {
        const user = state.user as UserOfficialStore

        return user.official_store.type_store
      }
      if (state.user && state.user.role === 'sales person') {
        const user = state.user as SalesPeople

        return user.sales_person.type_sales
      }

      return 'traditional'
    },
    remainingCredit (state) {
      const data = state.limit_debt - (state.total_debt + state.total_debt_invoice)

      return data > 0 ? data : 0
    },
  },
  actions: {
    setUser (data: User|UserOfficialStore|SalesPeople) {
      this.user = data
    },
    setTotalDebt (data: number) {
      this.total_debt = data
    },
    setTotalDebtRange1to3 (data1: number, data2: number, data3: number) {
      this.total_1_month = data1
      this.total_2_month = data2
      this.total_3_month = data3
    },
    setTotalDebtBiggerThan3Months (data: number) {
      this.total_debt_bigger_than_3_months = data
    },
    setTotalDebtInvoice (data: number) {
      this.total_debt_invoice = data
    },
    setLimitDebt (data: number) {
      this.limit_debt = data
    },
    minusPoints (data: number) {
      if (this.user) {
        this.user.points -= data
      }
    },
    logout () {
      this.loggingOut = true
      const api = useApiStore()
      api.POST('api/logout', { device_key: this.deviceKey })
      this.router.replace({ name: 'login' })
      this.$reset()
    },
  },
})