export default [
  {
    path: '/login',
    component: () => import('@/layouts/auth/layout-auth.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/auth/page-login.vue'),
      },
    ],
  },
]