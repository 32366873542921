<script setup lang="ts">
  import { computed } from 'vue'
  import { avatarGenerator } from '@/functions/avatar-generator'

  const props = defineProps<{
    src?: string | null
    name: string
    alt?: string
  }>()

  const src = computed(() => {
    return props.src ?? avatarGenerator(props.name)
  })
</script>

<template>
  <g-img class="overflow-hidden" :src="src" :alt="alt"></g-img>
</template>
