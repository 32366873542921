<script setup lang="ts">
  import { pwaInfo } from 'virtual:pwa-info'
  import { registerSW } from 'virtual:pwa-register'
  import { useApiStore } from './store/api'

  if (pwaInfo) {
    const href = pwaInfo.webManifest.href
    /* add link to head: href is the link */
    const linkElement = document.createElement('link')
    linkElement.setAttribute('rel', 'manifest')
    linkElement.setAttribute('href', href)
    if (pwaInfo.webManifest.useCredentials) {
      linkElement.setAttribute('crossorigin', 'use-credentials')
    }
    document.head.appendChild(linkElement)
  }

  const api = useApiStore()
  const initCsrfToken = async () => {
    await api.GET('sanctum/csrf-cookie')
  }

  registerSW({ immediate: true })

  initCsrfToken()
</script>

<template>
  <router-view></router-view>
  <g-toast></g-toast>
</template>
