<script setup lang="ts">
  import { nextTick, ref } from 'vue'

  interface Props {
    title: string,
    size?: 'medium'|'large',
  }

  withDefaults(defineProps<Props>(), {
    size: 'medium',
  })

  const display = ref(false)
  const dim = ref(false)

  const onEscapeListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close()
    }
  }

  const open = () => {
    dim.value = true
    nextTick(() => {
      display.value = true
    })

    document.addEventListener('keydown', onEscapeListener)
  }

  const close = () => {
    display.value = false
    document.removeEventListener('keydown', onEscapeListener)
  }

  const afterLeave = () => {
    dim.value = false
  }

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <div>
      <div v-if="dim" class="fixed inset-0 z-40 flex items-center justify-center">
        <div class="fixed inset-0 bg-black bg-opacity-40" @click="close"></div>
        <transition name="modal" @after-leave="afterLeave">
          <div
            v-if="display"
            class="z-50 flex w-full max-h-full p-4"
            :class="{'max-w-lg': size === 'medium', 'max-w-3xl': size === 'large'}">
            <div class="flex flex-col flex-1 px-4 py-2 bg-white sm:px-8 sm:py-4 rounded-xl">
              <slot name="header">
                <h2 v-if="title" class="text-xl font-bold text-center">
                  {{ title }}
                </h2>
              </slot>

              <div class="flex-1 pr-2 my-4 -mr-2 overflow-y-auto">
                <slot></slot>
              </div>

              <slot name="footer"></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
  .modal-enter-active,
  .modal-leave-active {
      transition: opacity 0.3s ease, margin-top 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
      margin-top: -50px;
      opacity: 0;
  }
</style>
