import dateFormatter from './date'
import numberFormatter from './number'

export default {
  ...numberFormatter,
  ...dateFormatter,
  url (str: string) {
    return location.protocol + '//' + import.meta.env.VITE_APP_URL + `/${str}`
  },
  kebab (str: string) {
    return str
      .replace(/[^\w\s]+/gm, '') // remove non alphanumeric
      .replace(/\s+/gm, '-') // replace multiple spaces to single dash
      .toLowerCase()
  },
}