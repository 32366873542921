export default [
  {
    path: '/dashboard/produk',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'product-index',
        component: () => import('@/views/dashboard/product/page-list.vue'),
      },
      {
        path: 'tambah',
        name: 'product-create',
        component: () => import('@/views/dashboard/product/page-create.vue'),
      },
      {
        path: ':id',
        name: 'product-detail',
        props: true,
        component: () => import('@/views/dashboard/product/show/page-show.vue'),
      },
    ],
  },
]