<script setup lang="ts">
  import { ref, watch } from 'vue'

  interface Props {
    src?: string | null
    alt?: string
    imgclass?: string
    type?: 'person' | 'default'
  }

  const props = withDefaults(defineProps<Props>(), {
    src: undefined,
    alt: undefined,
    imgclass: undefined,
    type: 'default',
  })

  const error = ref(false)

  watch(
    () => props.src,
    () => {
      error.value = false
    },
  )
</script>
<template>
  <div>
    <div
      v-if="!src || error"
      class="flex h-full w-full items-center justify-center bg-gray-100 text-gray-400"
    >
      <fa-icon
        v-if="type === 'person'"
        style="width: 80%; height: auto; margin-top: 0.5rem"
        icon="user-tie"
      ></fa-icon>
      <fa-icon
        v-else
        style="display: block; width: 75%; height: auto"
        icon="image"
      ></fa-icon>
    </div>
    <img
      v-else
      class="h-full w-full object-cover"
      :class="imgclass"
      :src="src"
      :alt="alt"
      @error="error = true"
    />
  </div>
</template>
