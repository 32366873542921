export default [
  {
    path: '/dashboard/sales',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'sales-index',
        component: () => import('@/views/dashboard/user/sales/page-list.vue'),
      },
      {
        path: ':id',
        name: 'sales-detail',
        props: true,
        component: () => import('@/views/dashboard/user/sales/show/page-show.vue'),
      },
    ],
  },
  {
    path: '/dashboard/toko',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'toko-index',
        component: () => import('@/views/dashboard/user/official-store/page-list.vue'),
      },
      {
        path: 'grup',
        name: 'toko-grup',
        component: () => import('@/views/dashboard/user/official-store/group/page-list.vue'),
      },
      {
        path: 'import',
        name: 'toko-import',
        component: () => import('@/views/dashboard/user/official-store/import-form.vue'),
      },
      {
        path: ':id',
        name: 'toko-detail',
        props: true,
        component: () => import('@/views/dashboard/user/official-store/show/page-show.vue'),
      },
    ],
  },
  {
    path: '/dashboard/admin',
    component: () => import('@/layouts/dashboard/layout-dashboard.vue'),
    children: [
      {
        path: '',
        name: 'admin-index',
        component: () => import('@/views/dashboard/user/admin/page-list.vue'),
      },
      {
        path: ':id',
        name: 'admin-detail',
        component: () => import('@/views/dashboard/user/admin/page-show.vue'),
      },
    ],
  },
]