import axios, { AxiosError } from 'axios'
import { AxiosRequestConfig } from 'axios'
import { defineStore } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from './auth'
import { usePageStore } from './page'
import { useToastStore } from './toast'

axios.defaults.withCredentials = true

const config = {
  baseURL: location.protocol + '//' + import.meta.env.VITE_APP_URL,
  timeout: 30000, // 30s
  headers: {
    Accept: 'application/json',
    // 'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': 'id',
  },
}

export const useApiStore = defineStore('Api', () => {
  const page = usePageStore()
  const toast = useToastStore()
  const auth = useAuthStore()
  const route = useRoute()
  const router = useRouter()

  const REQUEST = <T>(conf: AxiosRequestConfig) => {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const response = await axios.request({ ...config, ...conf })
        resolve(response.data)
      } catch (error) {
        handleErrors(error, resolve, reject, conf)
      }
    })
  }
  const GET = <T>(url: string, params = {}) => {
    return REQUEST<T>({ method: 'get', url, params })
  }
  const POST = <T>(url: string, data: object = {}) => {
    return REQUEST<T>({ method: 'post', url, data })
  }
  const PUT = <T>(url: string, data: object = {}) => {
    return REQUEST<T>({ method: 'put', url, data })
  }
  const DELETE = <T>(url: string, data = {}) => {
    return REQUEST<T>({ method: 'delete', url, data })
  }
  const POSTFORMDATA = <T>(url: string, formData: FormData) => {
    return REQUEST<T>({
      headers: { ...config.headers, 'Content-Type': 'multipart/form-data' },
      method: 'post',
      url,
      data: formData,
    })
  }
  const DOWNLOAD = async (url: string, filename: string, params?: object) => {
    const response = await REQUEST<BlobPart>({
      url,
      method: 'get',
      responseType: 'arraybuffer',
      headers: undefined,
      params: params,
    })

    downloadFile(response, filename)
  }
  const POSTDOWNLOAD = async (url: string, filename: string, data?: object) => {
    const response = await REQUEST<BlobPart>({
      url,
      method: 'post',
      responseType: 'arraybuffer',
      headers: undefined,
      data: data,
    })

    downloadFile(response, filename)
  }

  const downloadFile = (response: BlobPart, filename: string) => {
    const href = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  const onError = {
    unauthorized: () => {
      if (route.name === 'login') {
        return
      }
      auth.$reset()
      const path = route.fullPath.substring(1)
      const query = path !== '' ? { r: path } : undefined
      router.replace({ name: 'login', query })
    },
    maintenance: () => {
      router.replace({ name: 'maintenance' })
    },
    forbidden: () => {
      page.showForbiddenError()
    },
    notFound: () => {
      page.showNotFoundError()
    },
    csrfTokenMismatch: async<T>(
      resolve: (value: T) => void,
      reject: (reason: unknown) => void,
      conf: AxiosRequestConfig) => {
      try {
        await GET('/sanctum/csrf-cookie')
        resolve(await REQUEST<T>(conf))
      } catch (error) {
        reject(error)
      }
    },
    validationFailed: () => {
      if (route.name === 'login') {
        return
      }

      toast.add('Terdapat kesalahan pada data yang dikirim')
      setTimeout(() => {
        const errorMessage = document.querySelector('.error-message')
        errorMessage?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    tooManyRequest: () => {
      toast.add('Silakan tunggu beberapa menit sebelum mencoba kembali')
    },
    networkError: () => {
      toast.add('Tidak terhubung dengan internet')
    },
  }

  /* https://github.com/axios/axios#handling-errors */
  const handleErrors = <T>(
    error: unknown,
    resolve: (value: T) => void,
    reject: (reason: unknown) => void,
    conf: AxiosRequestConfig,
  ) => {
    if (!axios.isAxiosError(error)) {
      /**
       * Not axios error
       * Something happened in setting up the request that triggered an Error
       */
      reject(error)
      return
    }

    if (error.response) {
      /**
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      switch (error.response.status) {
      case 401: onError.unauthorized(); break
      case 403: onError.forbidden(); break
      case 404: onError.notFound(); break
      case 419: onError.csrfTokenMismatch(resolve, reject, conf); return
      case 422: onError.validationFailed(); break
      case 429: onError.tooManyRequest(); break
      case 503: onError.maintenance(); break
      default: break
      }
      if (error.message === 'Network Error') {
        onError.networkError()
      }
      reject(error)
      console.error('[Axios error]', error)
      return
    }

    /**
     * The request was made but no response was received.
     * `error.request` is an instance of XMLHttpRequest
     * in the browser and an instance of
     * http.ClientRequest in node.js
     */
    // if (error.request) {
    // }
    console.error('[API] Axios error but no response', error)
    reject(error)
  }

  const isNeedPasswordConfirmation = (error: unknown): boolean => {
    if (axios.isAxiosError(error)) {
      const err = error as AxiosError<{message?: string}>
      if (err.response?.data.message === 'Password confirmation required.' || error.response?.status === 423) {
        return true
      }
    }

    return false
  }

  const formErrors = (error: unknown): FormError => {
    if (axios.isAxiosError(error)) {
      const err = error as AxiosError<{errors: FormError}>
      if (err.response?.data.errors) {
        return err.response.data.errors
      }
    }

    return {}
  }

  return {
    GET,
    POST,
    DELETE,
    PUT,
    POSTFORMDATA,
    DOWNLOAD,
    POSTDOWNLOAD,
    config,
    formErrors,
    isNeedPasswordConfirmation,
  }
})