<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  interface Props {
    placeholder?: string,
    for?: string,
  }

  const props = defineProps<Props>()
  const route = useRoute()
  const router = useRouter()

  const text = ref('')
  const debounce = ref<NodeJS.Timeout>()
  text.value = route.query.search as string

  watch(text, () => {
    clearTimeout(debounce.value)
    debounce.value = setTimeout(() => {
      submitSearch()
    }, 1000)
  })

  const submitSearch = () => {
    if (props.for === 'shop') {
      router.push({
        path: '/produk', // name and params
        query: {
          // maintain other query params, but reset pagination
          ...route.query,
          page: undefined,
          // append or remove search to query params
          cari: text.value ? text.value : undefined,
        },
      })
    } else {
      router.push({
        ...route, // name and params
        query: {
          // maintain other query params, but reset pagination
          ...route.query,
          page: undefined,
          // append or remove search to query params
          cari: text.value ? text.value : undefined,
        },
      })
    }
  }
</script>

<template>
  <form class="relative" @submit.prevent="submitSearch">
    <input v-model="text" class="py-2 h-full pl-12 text-sm form-input" :placeholder="placeholder">
    <div class="absolute top-0 flex items-center h-full px-4">
      <fa-icon icon="search" class="text-gray-500" />
    </div>
  </form>
</template>