import { createPinia } from 'pinia'
import { registerSW } from 'virtual:pwa-register'
import { createApp, markRaw } from 'vue'
import { Router } from 'vue-router'
import '../css/app.css'
import App from './App.vue'
import globalComponents from './components'
import globalDirectives from './directives'
import router from './router'

const app = createApp(App)

const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app
  .use(pinia)
  .use(router)
  .use(globalComponents)
  .use(globalDirectives)
  .mount('#app')

registerSW({ immediate: true })

declare module 'pinia' {
  interface PiniaCustomProperties {
    router: Router
  }
}
