export default [
  {
    path: '/',
    component: () => import('@/layouts/shop/layout-shop.vue'),
    children: [
      {
        path: '',
        name: 'home-shop-index',
        component: () => import('@/views/shop/home/page-home.vue'),
      },
      {
        path: 'produk',
        name: 'shop-product-list',
        component: () => import('@/views/shop/product/page-list.vue'),
      },
      {
        path: 'produk/:id',
        name: 'shop-product-detail',
        props: true,
        component: () => import('@/views/shop/product/show/page-show.vue'),
      },
      {
        path: 'keranjang',
        name: 'cart-shop-index',
        component: () => import('@/views/shop/shopping-cart/page-list.vue'),
      },
      {
        path: 'checkout',
        name: 'cart-shop-checkout',
        component: () => import('@/views/shop/shopping-cart/page-checkout.vue'),
      },
      {
        path: 'pesanan',
        name: 'order-shop-index',
        component: () => import('@/views/shop/order/page-list.vue'),
      },
      {
        path: 'pesanan/:id',
        name: 'order-shop-detail',
        props: true,
        component: () => import('@/views/shop/order/show/page-show.vue'),
      },
      {
        path: 'profil',
        name: 'profile-shop-index',
        component: () => import('@/views/shop/profile/page-show.vue'),
      },
      {
        path: 'toko',
        name: 'list-store',
        component: () => import('@/views/shop/profile/official-store/page-list.vue'),
      },
      {
        path: 'toko/:id',
        name: 'detail-store',
        props: true,
        component: () => import('@/views/shop/profile/official-store/show/page-show.vue'),
      },
      {
        path: 'list-kredit',
        name: 'list-credits',
        component: () => import('@/views/shop/profile/order/page-list.vue'),
      },
      {
        path: 'notifikasi',
        name: 'notification-shop',
        component: () => import('@/views/shop/notification/page-list.vue'),
      },
      {
        path: 'riwayat-poin',
        name: 'point-log',
        component: () => import('@/views/shop/point-log/page-list.vue'),
      },
    ],
  },
]