<script setup lang="ts">
  import { nanoid } from 'nanoid'
  import { computed } from 'vue'
  import fmt from '@/functions/formatters'

  const props = defineProps<{
    id?: string
    modelValue: string
    type?: string
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    maxLength?: number
    rows?: number
    disabled?: boolean
  }>()

  const defaultId = nanoid()
  const inputId = computed(() => props.id ?? defaultId)

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val)
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }
</script>

<template>
  <div class="w-full overflow-hidden">
    <label v-if="label" :for="inputId">
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative flex items-center">
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        class="form-input"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        :rows="rows ?? 3"
        :required="required"
        :maxlength="maxLength"
        @input="onInput"
      ></textarea>
      <input
        v-else
        :id="inputId"
        class="form-input"
        :disabled="disabled"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :maxlength="maxLength"
        @input="onInput"
      />
      <div
        v-if="maxLength !== undefined"
        class="absolute bottom-1 right-2 bg-white text-3xs text-gray-500"
      >
        {{ fmt.number(maxLength - value.length) }}
      </div>
    </div>
    <g-form-error :value="error"></g-form-error>
  </div>
</template>

<style lang="postcss" scoped></style>
