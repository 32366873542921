import { format, formatDistanceToNowStrict } from 'date-fns'
import { id } from 'date-fns/locale'

export default {
  date: (dt: string|null|number|Date, fmt: string) => {
    if (!dt) return '-'

    return format(new Date(dt), fmt, { locale: id })
  },
  dateDiffHuman: (dt: string) => {
    const date = new Date(dt)

    return formatDistanceToNowStrict(date, { addSuffix: true, locale: id })
  },
}

