import { defineStore } from 'pinia'

type StatusNavbarShop = 'general'|'root'|'profile'|'hidden'|'search'

const TITLE_APP = import.meta.env.VITE_APP_NAME

interface State {
  title: string,
  status_navbar_shop: StatusNavbarShop,
  showError403: boolean,
  showError404: boolean,
  showMobileSidebar: boolean,
  back_navbar: object|null,
}

export const usePageStore = defineStore('Page', {
  state: (): State => ({
    title: '',
    status_navbar_shop: 'general',
    showError403: false,
    showError404: false,
    showMobileSidebar: false,
    back_navbar: null,
  }),
  actions: {
    setTitle (title: string) {
      this.title = title
      document.title = `${title} | ${TITLE_APP}`
    },
    setStatusNavbarShop (data: StatusNavbarShop) {
      this.status_navbar_shop = data
    },
    setBackNavbar (data: object|null) {
      this.back_navbar = data
    },
    resetErrorState () {
      this.showError403 = false
      this.showError404 = false
    },
    showNotFoundError () {
      this.showError404 = true
    },
    showForbiddenError () {
      this.showError403 = true
    },
    toggleMobileSidebar (value: boolean) {
      this.showMobileSidebar = value
    },
  },
})